/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import { Link } from 'react-router-dom';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Avatar } from "@material-tailwind/react";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction'; 
import Paper from '@mui/material/Paper';
 import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem, 
} from "@material-tailwind/react";

import {
  Drawer,
  Button,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Card,
} from "@material-tailwind/react"; 
import Iframe from 'react-iframe'
import { IconButton } from "@material-tailwind/react";
import React, { useRef, Fragment } from "react";
import ActionSheet from "actionsheet-react";


function refreshMessages() {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  return Array.from(new Array(50)).map(
    () => messageExamples[getRandomInt(messageExamples.length)],
  );
}
export default function Navbarsection() {
  
  const [open, setOpen] = React.useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
    const ref = useRef();
    const handleOpen = () => {
      ref.current.open();
    };
    const handleClose = () => {
      ref.current.close();
    };
  return (
    <> 
   <Fragment>
     
       <ActionSheet ref={ref}>
        <div>    <Iframe url="./postads.html"
       width="100%"
       height="480px" 
       display="block"
       position=""
       frameBorder="0"
       scrolling="yes"
       overflow="hidden"
       z-index="999999" /> </div>
     <button ripple={true}
  class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-red-500 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block w-full "
  type="button"
  onClick={handleClose} >
   <i className="fas fa-close" /> Close
</button> </ActionSheet>
    </Fragment>  
    
    
    <Box className="block md:hidden sticky top-0 z-[50] " sx={{ pb: 0 }} ref={ref}>
      
      <CssBaseline />
       
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, paddingLeft: 1,paddingRight: 1, }} elevation={5}>
        
        <BottomNavigation 
          showLabels
          value={value} onChange={handleChange}
        >
           <BottomNavigationAction  label="Home" value="Home" icon={<HomeIcon />}   LinkComponent={Link} to={'/'}/>
            

           <BottomNavigationAction label="Search" component={Link}
        to="https://www.m.areawize.com/search/" icon={<ImageSearchIcon />} />  


        <BottomNavigationAction label="Post"  
       onClick={handleOpen} icon={<AddToPhotosIcon />} /> 

       <BottomNavigationAction label="NewListing" component={Link}
        to="https://www.m.areawize.com/product-category/allcatlogs/" icon={<MovieFilterIcon />} /> 

{/* <BottomNavigationAction label="Account" component={Link}
        to="https://www.m.areawize.com/my-account/" icon={<PeopleAltOutlinedIcon />} />  */}

        </BottomNavigation>
      </Paper>
    </Box>
    <nav aria-label="Global" className="bg-opacity-95 bg-green-500 block sticky top-0 z-[50] ">
    
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
      <div className="flex h-16 items-center justify-between">
{/* Dextop logo  */}
        <div className="hidden md:block md:items-center md:gap-2 text-slate-200 mr-[12px]">
          <a className="block text-teal-600" href="/">
            <span className="sr-only"> Home </span>
            <img src="./assets/logo/di.webp" alt="" className="self-center w-31 h-12 mx-1 mr-[-22px]" />
          </a>
        </div>
{/* Mobile logo  */}
        <div className="block md:hidden  md:items-center md:gap-2 text-slate-200 mr-[12px]">
          <a className="block text-teal-600" href="/">
            <span className="sr-only"> Home </span>
            <img src="./assets/logo/mi.webp" alt="" className="self-center w-54 h-12 mx-1 mr-[3px]" />
          </a>
        </div> 
        <div className="hidden md:block">
          <nav aria-label="Global">
            <ul className="flex items-center gap-5 text-sm">

            <li>
                <a className="text-white hover:text-green-400" href="/">  Home </a>
              </li>

              <li>
                <a className="text-white  transition hover:text-green-400" href="/About"> About </a>
              </li>
             
              <li>
                <a className="text-white hover:text-green-400" href="/jobs">  Career </a>
              </li>  
     
              <li>
                <a className="text-white transition hover:text-green-400" href="/Blog"> Blog </a>
              </li>
              
              <li>
                
              <div className="hidden sm:flex ">
   <a
                className="rounded-md ms-" 
              >
                
 
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="hover:text-green-400 text-white hover:bg-teal  font-medium rounded-lg text-sm text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 "> 
More 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
</svg>

</button> 
 
      </MenuHandler> 
      <MenuList  className="mt-3">
      <MenuItem className="flex items-center gap-2">
      <svg className="text-ping-600" 
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14C1.26522 14 1.51957 13.8946 1.70711 13.7071C1.89464 13.5196 2 13.2652 2 13V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM11.293 9.293C11.1108 9.4816 11.01 9.7342 11.0123 9.9964C11.0146 10.2586 11.1198 10.5094 11.3052 10.6948C11.4906 10.8802 11.7414 10.9854 12.0036 10.9877C12.2658 10.99 12.5184 10.8892 12.707 10.707L15.707 7.707C15.8945 7.51947 15.9998 7.26516 15.9998 7C15.9998 6.73484 15.8945 6.48053 15.707 6.293L12.707 3.293C12.6148 3.19749 12.5044 3.12131 12.3824 3.0689C12.2604 3.01649 12.1292 2.9889 11.9964 2.98775C11.8636 2.9866 11.7319 3.0119 11.609 3.06218C11.4861 3.11246 11.3745 3.18671 11.2806 3.2806C11.1867 3.3745 11.1125 3.48615 11.0622 3.60905C11.0119 3.73194 10.9866 3.86362 10.9877 3.9964C10.9889 4.12918 11.0165 4.2604 11.0689 4.3824C11.1213 4.50441 11.1975 4.61475 11.293 4.707L12.586 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H12.586L11.293 9.293Z"
              fill="#90A4AE"
            />
          </svg>
 
          <Typography variant="small" className="font-medium">
          <a href="/faq" className="hover:text-blue-800 text-[14px] text-black"  > FAQ  </a>
          </Typography>
        </MenuItem>

        <MenuItem className="flex items-center gap-2">
      <svg className="text-ping-600" 
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14C1.26522 14 1.51957 13.8946 1.70711 13.7071C1.89464 13.5196 2 13.2652 2 13V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM11.293 9.293C11.1108 9.4816 11.01 9.7342 11.0123 9.9964C11.0146 10.2586 11.1198 10.5094 11.3052 10.6948C11.4906 10.8802 11.7414 10.9854 12.0036 10.9877C12.2658 10.99 12.5184 10.8892 12.707 10.707L15.707 7.707C15.8945 7.51947 15.9998 7.26516 15.9998 7C15.9998 6.73484 15.8945 6.48053 15.707 6.293L12.707 3.293C12.6148 3.19749 12.5044 3.12131 12.3824 3.0689C12.2604 3.01649 12.1292 2.9889 11.9964 2.98775C11.8636 2.9866 11.7319 3.0119 11.609 3.06218C11.4861 3.11246 11.3745 3.18671 11.2806 3.2806C11.1867 3.3745 11.1125 3.48615 11.0622 3.60905C11.0119 3.73194 10.9866 3.86362 10.9877 3.9964C10.9889 4.12918 11.0165 4.2604 11.0689 4.3824C11.1213 4.50441 11.1975 4.61475 11.293 4.707L12.586 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H12.586L11.293 9.293Z"
              fill="#90A4AE"
            />
          </svg>
 
          <Typography variant="small" className="font-medium">
          <a href="/Our_teams" className="hover:text-blue-800 text-[14px] text-black"  > Our Teams   </a>
          </Typography>
        </MenuItem>

        <MenuItem className="flex items-center gap-2">
      <svg className="text-ping-600" 
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14C1.26522 14 1.51957 13.8946 1.70711 13.7071C1.89464 13.5196 2 13.2652 2 13V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM11.293 9.293C11.1108 9.4816 11.01 9.7342 11.0123 9.9964C11.0146 10.2586 11.1198 10.5094 11.3052 10.6948C11.4906 10.8802 11.7414 10.9854 12.0036 10.9877C12.2658 10.99 12.5184 10.8892 12.707 10.707L15.707 7.707C15.8945 7.51947 15.9998 7.26516 15.9998 7C15.9998 6.73484 15.8945 6.48053 15.707 6.293L12.707 3.293C12.6148 3.19749 12.5044 3.12131 12.3824 3.0689C12.2604 3.01649 12.1292 2.9889 11.9964 2.98775C11.8636 2.9866 11.7319 3.0119 11.609 3.06218C11.4861 3.11246 11.3745 3.18671 11.2806 3.2806C11.1867 3.3745 11.1125 3.48615 11.0622 3.60905C11.0119 3.73194 10.9866 3.86362 10.9877 3.9964C10.9889 4.12918 11.0165 4.2604 11.0689 4.3824C11.1213 4.50441 11.1975 4.61475 11.293 4.707L12.586 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H12.586L11.293 9.293Z"
              fill="#90A4AE"
            />
          </svg>
 
          <Typography variant="small" className="font-medium">
          <a href="/Catlog" className="hover:text-blue-800 text-[14px] text-black"  > Our Category    </a>
          </Typography>
        </MenuItem>


        <MenuItem className="flex items-center gap-2">
      <svg className="text-ping-600" 
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14C1.26522 14 1.51957 13.8946 1.70711 13.7071C1.89464 13.5196 2 13.2652 2 13V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM11.293 9.293C11.1108 9.4816 11.01 9.7342 11.0123 9.9964C11.0146 10.2586 11.1198 10.5094 11.3052 10.6948C11.4906 10.8802 11.7414 10.9854 12.0036 10.9877C12.2658 10.99 12.5184 10.8892 12.707 10.707L15.707 7.707C15.8945 7.51947 15.9998 7.26516 15.9998 7C15.9998 6.73484 15.8945 6.48053 15.707 6.293L12.707 3.293C12.6148 3.19749 12.5044 3.12131 12.3824 3.0689C12.2604 3.01649 12.1292 2.9889 11.9964 2.98775C11.8636 2.9866 11.7319 3.0119 11.609 3.06218C11.4861 3.11246 11.3745 3.18671 11.2806 3.2806C11.1867 3.3745 11.1125 3.48615 11.0622 3.60905C11.0119 3.73194 10.9866 3.86362 10.9877 3.9964C10.9889 4.12918 11.0165 4.2604 11.0689 4.3824C11.1213 4.50441 11.1975 4.61475 11.293 4.707L12.586 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7C4 7.26522 4.10536 7.51957 4.29289 7.70711C4.48043 7.89464 4.73478 8 5 8H12.586L11.293 9.293Z"
              fill="#90A4AE"
            />
          </svg>
 
          <Typography variant="small" className="font-medium">
          <a href="mailto:contact@hickter.in" className="hover:text-blue-800 text-[14px] text-black"  > Support | Mail  </a>
          </Typography>
        </MenuItem>


        </MenuList>
        
        
        </Menu>
         
        </a>
         
        </div> 
    
              </li>
 
            </ul>
          </nav>
        </div>
  
  {/* Only Mobile  */}

        <div className="flex items-center gap-4">
 
{/* Usermenu Mobile   */}
<div className="block md:hidden ml-[1px]">
   <a className="rounded-md ms-"  >
                
 
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-black bg-white hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-3  py-2.5 text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-2px]">

                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-blue-900 hover:text-black  w-6 h-6">
  <path fill-rule="evenodd" d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
  <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
</svg>
 
</button>
      </MenuHandler>
      
      <MenuList>
       
      <MenuItem>
        <a href="https://wa.link/za0k8n"> <Button
           size="sm"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/comments.png" alt="avatar" className="pr-1 ml-1" />  Message Or  Chat 
         </Button></a>
         </MenuItem>
 
         <MenuItem>
        <a href="tel:917991099207"> <Button
           size="sm"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/phone.png" alt="avatar" className="pr-1 ml-1" />   Talk to Us   
         </Button></a>
         </MenuItem>

         <MenuItem>
        <a href="/Contact"> <Button
           size="lg"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/cn.png" alt="avatar" className="pr-1 ml-1" />   Contact Us 
         </Button></a>
         </MenuItem>

        
      </MenuList>
    </Menu>

              </a>
            </div>

 
                 <div className="block md:hidden ml-[1px]">
   <a  className="rounded-md ms-"  >
                
 
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-black bg-white hover:text-blue-800 hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-3 py-2  text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-2px]">
     

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-blue-800 hover:text-black w-7 h-7">
    
  <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
</svg>   MyAccount </button> 
      </MenuHandler> 
      <MenuList>
       
       <MenuItem>
         <a href="https://www.m.areawize.com/my-account/"> <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/user.png" alt="avatar" className="pr-1 ml-1" />  Login/Signup
          </Button></a>
          </MenuItem>
 
          
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/"
        
         
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/profile.png" alt="avatar" className="textpr-1 ml-1" /> MyAccount
          </Button></a>
          </MenuItem>
 
 
 
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/orders/"
         
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}  >
         <Avatar  size="ls" src="./assets/icons/order.png" alt="avatar" className="textpr-1 ml-1" />   My Booking
          </Button></a>
          </MenuItem>
 
  
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/downloads/"
          
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/received.png" alt="avatar" className="textpr-1 ml-1" /> Downloads
          </Button></a>
          </MenuItem>
 
  
       </MenuList>
  
    </Menu> 
              </a>
            </div>

            <div className="block md:hidden">   
        <a>  <Button onClick={openDrawer} className="mr-[-20px] text-black bg-white rounded p-2 text-black-50 transition hover:text-gray-600/75 mb-0" type="button" > <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7"/>
  </svg> </Button> </a>
         
                 </div>

                 {/* DrawerMenu Mobile   */}

                 <React.Fragment>
      

      <Drawer className="bg-green-900 z-50"  open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">

          
          <Typography variant="h5" className="text-white hover:text-white	text-[13px]" color="blue-gray">
            Menu View
          </Typography>
          <IconButton variant="text" className="text-white bg-red-500" onClick={closeDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="text-white hover:text-white h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>

        <List className="mt-[-15px] p-0">
          {/* Home Section  */}
       
        <a href="https://www.m.areawize.com/my-account/">
        <ListItem  className="group rounded-none py-1.5 px-3 text-sm font-normal  text-white  hover:text-blue-200
         focus:text-pink-400 ">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
</svg>
 
          </ListItemPrefix>
      Login / Signup 
          <ListItemSuffix>
            
          </ListItemSuffix>
        </ListItem></a>

      
     
{/* About Section  */}

        <a href="/About">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>

  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > About Us | Areawize   </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>

       
       {/* Services  Section  */}
        <a href="/Catlog">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
</svg>


  </ListItemPrefix> 
  <p className="text-[14px] mr-[50px]" > Category  | Areawize </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>


 {/* Agents  Section  */}
 <a href="/Agents">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          
<svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4"/>
</svg>

  
  </ListItemPrefix> 
  <p className="text-[14px] mr-[50px]" > Agents Section</p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>

{/* Suggestion  Section  */}
<a href="/Suggestion">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          
          <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18.5A2.493 2.493 0 0 1 7.51 20H7.5a2.468 2.468 0 0 1-2.4-3.154 2.98 2.98 0 0 1-.85-5.274 2.468 2.468 0 0 1 .92-3.182 2.477 2.477 0 0 1 1.876-3.344 2.5 2.5 0 0 1 3.41-1.856A2.5 2.5 0 0 1 12 5.5m0 13v-13m0 13a2.493 2.493 0 0 0 4.49 1.5h.01a2.468 2.468 0 0 0 2.403-3.154 2.98 2.98 0 0 0 .847-5.274 2.468 2.468 0 0 0-.921-3.182 2.477 2.477 0 0 0-1.875-3.344A2.5 2.5 0 0 0 14.5 3 2.5 2.5 0 0 0 12 5.5m-8 5a2.5 2.5 0 0 1 3.48-2.3m-.28 8.551a3 3 0 0 1-2.953-5.185M20 10.5a2.5 2.5 0 0 0-3.481-2.3m.28 8.551a3 3 0 0 0 2.954-5.185"/>
</svg>


  
  </ListItemPrefix> 
  <p className="text-[14px] mr-[50px]" > Suggestion Section</p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>


     {/* Blog  Section  */}

        <a href="/Blog">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
</svg>


  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > Blogs </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>

     {/* Jobs   Section  */}
        <a href="/jobs">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
  <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
</svg>


  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > Jobs Apply | Career  </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>

         {/* Portfolio   Section  */}
        <a href='/Postads'> 
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
</svg>
 


  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > Post Ads | Areawize  </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem > </a> 

 {/* Search    Section  */}
 <a href="https://www.m.areawize.com/search/">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
</svg>

 

  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > Search  | Areawize </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>
 
  {/* Plans   Section  */}
  <a
        href="https://www.m.areawize.com/my-account/orders/"
         
        rel="noreferrer"
      > 
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375Z" />
  <path fill-rule="evenodd" d="M8 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2Zm6 1h-4v2H9a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2h-1V4Zm-3 8a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-2-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H9Zm2 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-2-1a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H9Z" clip-rule="evenodd"/>
</svg>

 


  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > My Booking  </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>
        {/* Downloads   Section  */}
        <a
      href="https://www.m.areawize.com/my-account/downloads/"
       
      rel="noreferrer"
    > 
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M12 1.5a.75.75 0 0 1 .75.75V7.5h-1.5V2.25A.75.75 0 0 1 12 1.5ZM11.25 7.5v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h3.75Z" />
</svg>



  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > My Downloads  </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>

 {/* Teams Section  */}
 <a href="/Our_team_hickter">
           <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>


  </ListItemPrefix> <p className="text-[14px] mr-[50px]" > Teams  </p>

          <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-8 mr-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix> 
        </ListItem></a>
       
      </List>

      {/* End Menu Section First  */}

      <a href="/Contact" >  <Button className="hover:bg-white bg-blue-300 mt-2 ml-5" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal  text-black hover:bg-white hover:text-black focus:bg-white focus:text-black">
          <ListItemPrefix>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M8.625.75A3.375 3.375 0 0 0 5.25 4.125v15.75a3.375 3.375 0 0 0 3.375 3.375h6.75a3.375 3.375 0 0 0 3.375-3.375V4.125A3.375 3.375 0 0 0 15.375.75h-6.75ZM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 0 1 7.5 19.875V4.125Z" clip-rule="evenodd" />
</svg>

  </ListItemPrefix> <p  className="text-[14px] mr-[12px]" > Contact Us   </p>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
      </Drawer>
    </React.Fragment> 
       



{/* Only Dextop  */}

     
{/* Pricing  Dextop  */}

{/* Postads  Dextop  */}
<div className="ml-[1px] hidden sm:flex ">
   <a className="rounded-md ms-8" >
                
 
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-black bg-white hover:text-blue-800 hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-36px]">

      <svg  class="text-blue-800 hover:text-black w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
</svg>
  Post Ads  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />

</svg>

</button> 
      </MenuHandler>
      
      <MenuList>
      <MenuItem>
        <a href="/Postads"> <Button
           size="lg"
           color="green"
           className="pt-3 p-[12px] pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/postads.png" alt="avatar" className="pr-1 ml-1" />   Post Ads On | Areawize 
         </Button></a>
         </MenuItem>
      
 
      </MenuList>
    </Menu>

              </a>
            </div>

 
            {/* Search Listing On Areawise  */}
<div className="ml-[1px] hidden sm:flex ">
   <a className="rounded-md ms-8" >
                
 
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-black bg-white hover:text-blue-800 hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-36px]">

      <svg  class="text-blue-800 hover:text-black w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
</svg>
  Search Listing   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
<path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />

</svg>

</button> 
      </MenuHandler>
      
      <MenuList>
      <MenuItem>
        <a href="https://www.m.areawize.com/search/"> <Button
           size="lg"
           color="green"
           className="pt-3 p-[12px] pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/sl.png" alt="avatar" className="pr-1 ml-1" />  Search Listing 
         </Button></a>
         </MenuItem>
       
 
      </MenuList>
    </Menu>

              </a>
            </div>

 
         {/* Contact Section | Dextop  */}  
 
            <div className="hidden sm:flex  ml-[1px]">
   <a className="rounded-md ms-8"
                href="/Contact" >
           
<Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-blue-800 hover:text-black  bg-white hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-[10px] py-2.5 text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-40px]">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
  <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
</svg>  
Contact  
</button> 
      </MenuHandler>
      
      <MenuList>
       
      <MenuItem>
        <a href="https://wa.link/za0k8n"> <Button
           size="sm"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/comments.png" alt="avatar" className="pr-1 ml-1" />  Message Or  Chat 
         </Button></a>
         </MenuItem>
 
         <MenuItem>
        <a href="tel:917991099207"> <Button
           size="sm"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/phone.png" alt="avatar" className="pr-1 ml-1" />   Talk to Us   
         </Button></a>
         </MenuItem>

         <MenuItem>
        <a href="/Contact"> <Button
           size="lg"
           color="green"
           className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
        <Avatar  size="sm" src="./assets/icons/cn.png" alt="avatar" className="pr-1 ml-1" />   Contact Us 
         </Button></a>
         </MenuItem>

        
      </MenuList>
    </Menu>

              </a>
            </div> 


 <div className="hidden sm:flex  ml-[1px]">
   <a className="rounded-md ms-8"
                href="#" >
           
           <Menu allowHover>
      <MenuHandler>
      <button type="button" className="text-black bg-white hover:text-blue-800 hover:bg-teal focus:ring-4 focus:outline-none focus:ring-white/50 font-medium rounded-lg text-sm px-3 py-2  text-center inline-flex items-center dark:hover:bg-[#050708]/40 dark:focus:ring-gray-600 me-2 mr-[-2px]">
     

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-blue-800 hover:text-black w-7 h-7">
    
  <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
</svg>   MyAccount </button> 
      </MenuHandler> 
      <MenuList>
       
       <MenuItem>
         <a href="https://www.m.areawize.com/my-account/"> <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/user.png" alt="avatar" className="pr-1 ml-1" />  Login/Signup
          </Button></a>
          </MenuItem>
 
          
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/"
        
         
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/profile.png" alt="avatar" className="textpr-1 ml-1" /> MyAccount
          </Button></a>
          </MenuItem>
 
 
 
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/orders/"
         
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}  >
         <Avatar  size="ls" src="./assets/icons/order.png" alt="avatar" className="textpr-1 ml-1" />   My Booking
          </Button></a>
          </MenuItem>
 
  
          <MenuItem>
          <a
         href="https://www.m.areawize.com/my-account/downloads/"
          
         rel="noreferrer"
       > 
       <Button
            size="sm"
            color="green"
            className="text-[11px] pt-3 pb-3 hover:scale-[1.02] focus:scale-[1] active:scale-80"
            ripple={false}
            fullWidth={true}
          >
         <Avatar  size="ls" src="./assets/icons/received.png" alt="avatar" className="textpr-1 ml-1" /> Downloads
          </Button></a>
          </MenuItem>
 
  
       </MenuList>
  
    </Menu> 

              </a>
            </div> 

          <div className="sm:flex sm:gap-8"> 
          </div> 

        </div>
      </div>
    </div>
 
    <script src="../path/to/flowbite/dist/flowbite.js"></script> 
    <script src="https://cdn.tailwindcss.com"></script>  
</nav>
    </>
  )
}const messageExamples = [
  {
    primary: 'Brunch this week?',
    secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: '/static/images/avatar/5.jpg',
  },
  {
    primary: 'Birthday Gift',
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: '/static/images/avatar/1.jpg',
  },
  {
    primary: 'Recipe to try',
    secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
    person: '/static/images/avatar/2.jpg',
  },
  {
    primary: 'Yes!',
    secondary: 'I have the tickets to the ReactConf for this year.',
    person: '/static/images/avatar/3.jpg',
  },
  {
    primary: "Doctor's Appointment",
    secondary: 'My appointment for the doctor was rescheduled for next Saturday.',
    person: '/static/images/avatar/4.jpg',
  },
  {
    primary: 'Discussion',
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: '/static/images/avatar/5.jpg',
  },
  {
    primary: 'Summer BBQ',
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: '/static/images/avatar/1.jpg',
  },
];

 
 