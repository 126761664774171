/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter,BsLinkedin,BsYoutube,BsSkype,BsPinterest,BsTelegram } from 'react-icons/bs';

function Footersection() {
  return (
   <>
 
    <Footer className='bg-black bg-opacity-90' >
      <div className="w-full">
        <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
          <div>
            <Footer.Title className='text-pink-300' title="Company" />
            <Footer.LinkGroup className='text-white' col>
              <Footer.Link href="/About">About</Footer.Link>
              <Footer.Link href="/Jobs">Careers</Footer.Link>
              <Footer.Link href="/Contact">Contact Us </Footer.Link>
              <Footer.Link href="/Blog">Blog</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-pink-300'  title="help center" />
            <Footer.LinkGroup className='text-white' col>
              <Footer.Link href="mailto:support@areawize.com">Support </Footer.Link> 
              <Footer.Link href="/Contact">Contact Us</Footer.Link>
              <Footer.Link href="mailto:contact@areawize.com" > Mail </Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-pink-300' title="legal" />
            <Footer.LinkGroup className='text-white' col>
              <Footer.Link href="/Privacy">Privacy Policy</Footer.Link> 
              <Footer.Link href="/Terms">Terms &amp; Conditions</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className='text-pink-300' title="Quick Links " />
            <Footer.LinkGroup className='text-white' col>
              <Footer.Link href="/Catlog"> Our Category </Footer.Link>
              <Footer.Link href="/Searchlisting"> Search  </Footer.Link>  
              <Footer.Link href="/Postads"> Post Ads </Footer.Link>  
             
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full bg-green-600 bg-opacity-100 px-4 py-6 sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright className='text-white' href="mailto:support@areawize.com"  target="_blank" by="Areawize" year={2024} /><br></br>
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon className='text-white' href="https://www.facebook.com/" icon={BsFacebook} />
            <Footer.Icon className='text-white' href="https://www.instagram.com//" icon={BsInstagram} />
            <Footer.Icon className='text-white' href="https://twitter.com/" icon={BsTwitter} />
            <Footer.Icon className='text-white' href="https://www.youtube.com/channel/UC_G8P3qpxRzJTn27nWZ6lDQ" icon={BsYoutube} />
            <Footer.Icon className='text-white' href="https://in.linkedin.com/company/" icon={BsLinkedin} />
            <Footer.Icon className='text-white' href="https://t.me/" icon={BsTelegram} />
            <Footer.Icon className='text-white' href="https://in.pinterest.com//" icon={BsPinterest} />
            <Footer.Icon className='text-white' href="https://join.skype.com/invite/mowrCrqLYYbV" icon={BsSkype} />
          </div>
        </div>
      </div>
    </Footer>
  

   </>
  )
}

export default Footersection