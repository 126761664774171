/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { IconButton } from "@material-tailwind/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
export default function Pricingsection() {
  return (
   <>
    
  <section className="lg:mt-[20px] ">
  <div class="mx-auto">
      <div  class="mb-[-26px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-2xl"> Our Services & Pricing   </h3>
        </div>
      </div>
 

      <Carousel responsive={responsive}  className="z-[0] overscroll-x-none lg:p-9 lg:mt-[-10px]  p-6 mr-8 " >

 {/* App Development   */}
  <div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
App Development 
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[-3px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-3 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]"> ₹14,000 </span>{""}
          <span className="self-end text-[14px]"> 
          / OneTime </span> 

        </Typography> 
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div> 
 
  <Typography>
        ✔ Build apps for business with  full-stack Level Features With frontend UI, and backends with authentication, Etc.
        </Typography>
  
        <Typography className="text-center">

        <div className="flex gap-4">

      <div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
     
            <i style={{fontSize:35}} class="fab fa-react"></i>
            <p className="text-[13px]" > React </p>
      </div> 

      <div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <i style={{fontSize:35}} class="fab fa-java"></i>
      
     <p className="text-[13px]" > Java </p>
</div> 

<div className="bg-teal-800 auto text-center p-2 mt-2 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <i style={{fontSize:35}} class="fab fa-swift"></i>
      
     <p className="text-[13px]" > Swift </p>
</div> 

 
<div className="bg-teal-800 auto p-2 mt-2 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-2 h-10 w-9"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706246791/Hickter%20Files/Icons/iconsfiles/flutter-icon-1651x2048-ojswpayr.png"
          alt=""
        /> <p className="text-[13px]" > Flutter  </p>
      </div> 
       
 
      {/* <div className="bg-teal-800 auto p-2 mt-2 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-2 h-10 w-9"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706247200/Hickter%20Files/Icons/iconsfiles/Kotlin_Icon.svg.png"
          alt=""
        /> <p className="text-[13px]" > Kotlin  </p>
      </div>  */}

 
    </div>
        </Typography>
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" >
          <td>
          <a href="/App_demo"> <Button className="bg-blue-600"> <i style={{fontSize:14}} class="fa-solid fa-eye"></i> Check Demo  </Button></a>
          </td>
          <td>
          <a href="/App_development_hickter">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td>


        </tr></div>
       
      </CardFooter>
      
      </Card></div>

 {/*Website Development   */}


      <div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
         Website Development 
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[-3px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-3 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]"> ₹7,000 </span>{""}
          <span className="self-end text-[14px]"> 
          / OneTime </span> 

        </Typography> 
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div> 
 
  <Typography>
        ✔ We Develop and Design Responsive Website With Fullstack Features for any business Commerce , Retail , Etc.
        </Typography>
        
        <Typography className="mt-2 text-center">
        <div className="flex gap-1">

<div className="bg-teal-800 auto text-center  p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">

      <i style={{fontSize:30}} class="fab fa-php"></i>
      <p className="text-[13px]" > php </p>
</div> 

<div className="bg-teal-800 auto text-center p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
<i style={{fontSize:30}} class="fab fa-html5"></i>

<p className="text-[13px]" > HTML </p>
</div> 

<div className="bg-teal-800 auto text-center p-2 mt-1 mb-1 text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
<i style={{fontSize:26}} class="fab fa-laravel"></i>

<p className="text-[13px]" > Laravel </p>
</div> 


{/* <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
<i style={{fontSize:24}} class="fab fa-js-square"></i><p className="text-[13px]" > Javascript  </p>
</div> */}

<div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
<i style={{fontSize:25}} class="fab fa-wordpress"></i><p className="text-[13px]" > Wordpres  </p>
</div>  
 

 


</div>
 
        </Typography>
      </CardBody>
      <CardFooter className=" mt-[-20px]" >
      <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
       <tr className=" gap-x-2	" >
          <td>
          <a href="/website_demo"> <Button className="bg-blue-600"> <i style={{fontSize:14}} class="fa-solid fa-eye"></i> Check Demo  </Button></a>
          </td>
          <td>
          <a href="/website_development_hickter">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
</td>


        </tr></div>
       
      </CardFooter>
      
      </Card></div>



 {/* Digital Marketing   */}
 <div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
         Digital Marketing 
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[4px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-3 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹5,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography>
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div> 
 
  <Typography>
        ✔ Now Grow Your Business By Reaching to Millions Customers to Grow.
        </Typography>
       
        <Typography className="text-left">
        <div className="flex gap-1">
 
<div className="bg-teal-900 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="ml-5 text-center h-10 w-9"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264299/Hickter%20Files/Icons/iconsfiles/Logo_Icons__6_-removebg-preview.png"
          alt=""
        /> <p className="text-[13px]" > SEO Marketing  </p>
      </div> 

<div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-1 ml-2 h-10 w-11"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264382/Hickter%20Files/Icons/iconsfiles/social-media_2.png"
          alt=""
        /> <p className="text-[13px]" > Social Ads   </p>
      </div> 

<div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center h-10 ml-2 w-13"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264568/Hickter%20Files/Icons/iconsfiles/meta.png"
          alt=""
        /> <p className="text-[13px]" > Meta Ads  </p>
      </div> 
 
      <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
      <img className="text-center ml-2 p-2 h-10 w-11"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706264949/Hickter%20Files/Icons/iconsfiles/adwords.png"
          alt=""
        /> 
        <p className="text-[13px]" > Google Ads </p>
      </div> 


     {/*  <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="auto text-left p- h-10 w-13 ml-3.5"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706428659/Hickter%20Files/Icons/iconsfiles/Untitled_design__16_-removebg-preview.png"
          alt=""
        /> <p className="ml-1 text-[13px]" > Ads Promotion   </p>
      </div>  */}
 
</div>
  
        </Typography>
      </CardBody>
      <CardFooter className="mt-[-30px]" >
      <a href="/Digital_marketing_hickter">  <Button 
           size="lg"
           color="white"
           className="pt-3 text-white bg-blue-500 pb-3 mt-[-4px] hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          Start Now 
         </Button></a>
      </CardFooter>
      
      </Card></div>


{/* Banner Ads | Promotion   */}

<div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
         Banner Ads | Promotion
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[-10px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-3 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹5,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography>
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div>  
  <Typography>
        ✔  Make Awesome BannerAds Design For Your Business . 
        </Typography>
      
        <Typography className="mt-0 text-center">
        <div className="flex gap-1">
 
 <div className="bg-teal-900 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="ml-0 text-center h-13 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706281190/Hickter%20Files/Icons/iconsfiles/Canva-logo.png"
           alt=""
         /> <p className="text-[13px]" > Canva Design  </p>
       </div> 
 
 <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="text-center p-1 h-13 ml-2 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706281685/Hickter%20Files/Icons/iconsfiles/Adobe_Photoshop_Express_logo.svg.png"
           alt=""
         /> <p className="text-[13px]" > Photoshop Design   </p>
       </div> 
 
 <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="text-center h-13 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706281815/Hickter%20Files/Icons/iconsfiles/paint-canvas.png"
           alt=""
         /> <p className="text-[13px]" > Ads Design  </p>
       </div> 
  
       <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
       <img className="text-center p-2 h-13 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706282411/Hickter%20Files/Icons/iconsfiles/instagram-post.png"
           alt=""
         /> 
         <p className="text-[13px]" >  Ads Posting </p>
       </div> 
 
  
 </div>
 
        </Typography>
      </CardBody>
      <CardFooter className=" mt-[-30px]" >
      <a href="/Banner_ads_hickter">  <Button 
           size="lg"
           color="white"
           className="pt-3 text-white bg-blue-500 pb-3 mt-[-4px] hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          Start Now 
         </Button></a>
      </CardFooter>
      
      </Card></div>


{/*  Video Ads | Promotion  */}
<div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
         Video Ads | Promotion 
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[-10px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-2 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹7,000 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography>
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div>  
  <Typography>
        ✔  Promotional Video and Ads For Your Business.
        </Typography> 
      
        <Typography className="text-center">
        <div className="flex gap-1">
 
 <div className="bg-teal-900 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="ml-0 text-center h-13 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706284110/Hickter%20Files/Icons/iconsfiles/video_ads_icon_154531.png"
           alt=""
         /> <p className="text-[13px]" >  Video Ads   </p>
       </div> 
 
 <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="text-center p-1 h-13 ml-0 w-14"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706339394/Hickter%20Files/Icons/iconsfiles/video-advertising.png"
           alt=""
         /> <p className="text-[13px]" > Ads Promotion   </p>
       </div> 
 
 <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
       <img className="text-center h-13 w-14 "
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706433383/Hickter%20Files/Icons/iconsfiles/download-removebg-preview_3.png"
           alt=""
         /> <p className="text-[13px]" > Video Editing </p>
       </div> 
  
       <div className="bg-teal-800 auto p-2 mt-1 mb-1 text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10" >
       <img className="text-center p-2 h-13 w-14 ml-2"
           src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706340049/Hickter%20Files/Icons/iconsfiles/video-marketing.png"
           alt=""
         /> 
         <p className="text-[13px]" >  Video Ads Posting </p>
       </div> 
  
 </div>
 
        </Typography>
      </CardBody>
      <CardFooter className=" mt-[-30px]" >
      <a href="/Video_ads_hickter">  <Button 
           size="lg"
           color="white"
           className="pt-3 text-white bg-blue-500 pb-3 mt-[-4px] hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          Start Now 
         </Button></a>
      </CardFooter>
      
      </Card></div>

{/*  Logo Design   */}
<div className="p-[5px] mt-[10px]">  
<Card  variant="gradient" className="p-[12px] bg-purple-900	
     mx-auto rounded ">
         <CardHeader color="blue-gray" className="mt-[-11px] text-[14px] auto p-2 text-center">
         Logo Design
          </CardHeader> 
          
          <CardBody className="bg-white" >
          
          <Typography
          variant="h1"
          color="black"
          className="mt-[-10px] flex justify-center gap-1 text-7xl font-normal"><span className="mb-2 text-orange-400 font-bold self-end text-[14px]"> Start <br></br>From  </span> 
          <span className="mt-0 text-[32px]">₹500 </span>{""}
          <span className="self-end text-[14px]"> / Month </span>  
        </Typography>
        <div
    class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-teal-300 py-3 px-3 font-sans text-xs uppercase text-black mt-[2px] ">
    <span class=""> * ₹6000 / Yearly Maintainance Charge </span>
  </div>  
  <Typography>
        ✔ Create a Stuning Design Logo For Your Business Or Brand and Grow Your a Valuable Organization in this new era.
        </Typography> 
      
        <Typography className="mt-[40px]  text-center">
        <div className="flex gap-4">

      <div className=" auto text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p- h-12 w-14"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706340962/Hickter%20Files/Icons/iconsfiles/WUR4QXtd_400x400-removebg-preview.png"
          alt=""
        /> <p className="text-[13px]" >  </p>
      </div>  
      <div className=" auto text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p- h-12 w-19"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706341775/Hickter%20Files/Icons/iconsfiles/download-removebg-preview_2.png"
          alt=""
        /> <p className="text-[13px]" >    </p>
      </div> 

      <div className="  auto text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-2 h-12 w-16"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706341598/Hickter%20Files/Icons/iconsfiles/Apple-Logo-500x281-min-removebg-preview.png"
          alt=""
        /> <p className="text-[13px]" >    </p>
      </div> 

      <div className="bg-green-200 auto text-center text-white rounded hover:shadow-[#ea4335]/20 focus:shadow-[#ea4335]/20 active:shadow-[#ea4335]/10">
      <img className="text-center p-2 h-14 w-18"
          src="https://res.cloudinary.com/dubz8k0bw/image/upload/v1706341344/Hickter%20Files/Icons/iconsfiles/Domino_s-logo-2021-removebg-preview.png"
          alt=""
        /> <p className="text-[13px]" >    </p>
      </div> 
        

    </div>
        </Typography>
      </CardBody>
      <CardFooter className=" mt-[-30px]" >
      <a href="/logo_design_hickter">  <Button 
           size="lg"
           color="white"
           className="pt-3 text-white bg-blue-500 pb-3 mt-[-4px] hover:scale-[1.02] focus:scale-[1] active:scale-80"
           ripple={false}
           fullWidth={true}
         >
          Start Now 
         </Button></a>
      </CardFooter>
      
      </Card></div>
 
 
</Carousel>
 
  </section>

  
   
   </>
  )
}
 
