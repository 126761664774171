/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from 'react'
  import { 
  Button, 
  ListItem,
  ListItemPrefix,
  ListItemSuffix, 
} from "@material-tailwind/react";
import Navbarsection from './views/Home/Navbarsection';
import Footersection from './views/Home/Footersection';
export default function Thanks() {
  return (
   <>
 <Navbarsection/>
 <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-screen-sm sm:text-center sm:mx-auto">
        <a
          href="/"
          aria-label="View"
          className="inline-block mb-5 rounded-full sm:mx-auto"
        >
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50">
          <svg className="text-blue-700 w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"/>
</svg>
          </div>
        </a>
        <h2 className="mb-4 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Thanks For Submission | Our Team Will Contact U Soon ! 
        </h2>
        <p className="text-base text-gray-700 md:text-lg sm:px-4">
          Just Mail us Directly : contact@areawize.com
        </p>
        <hr className="w-full my-8 border-gray-300" />

        <br></br>
        <a href="/" >  <Button className="mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="/" className="text-[14px] mr-[2px]" > Back To Home     </a>

  <ListItemSuffix>
  <svg className="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
</svg>


          </ListItemSuffix>
        </ListItem> 
        </Button></a>
      </div>
     
    </div>
 
 <Footersection/>
   </>
  )
}
