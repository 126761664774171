/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';

export default function Feature() {
  return (
   <> 
 <section class="text-gray-600 bg-blue-400 body-font">
 <div class="mx-auto">
      <div  class="mt-[0px] text-white body-font  ">
      <br></br>  <h3 class="text-center lg:text-[22px] font-bold sm:text-3xl"> Our Category     </h3>
        </div>
      </div>
  
<div class="container lg:mt-[50px] md:mt-[50px] px-5 py-5 mx-auto">
    <div class="flex lg:pl-[150px] flex-wrap text-center">
       
      <div class="mt-5 p-2 sm:w-1/3 w-1/2">
      <a href='https://www.m.areawize.com/product-category/property-rent/' > <Card>
          <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">
        <img
    className="h-18 max-w-full rounded-lg object-cover object-center "
    src="./assets/catlog/c1.gif"
    alt="gallery-photo"
  />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>    Rented Home  
          </h6>  
          </Card></a> 
      </div>


      <div class="mt-5 p-2 sm:w-1/4 w-1/2">
      <a href='https://www.m.areawize.com/product-category/rent-shop/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">
      <img
    className="h-18 max-w-full rounded-lg object-cover object-center "
    src="./assets/catlog/c2.webp"
    alt="gallery-photo"
  />
   
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>    Rented Shop 
          </h6>  
          </Card></a> 
           </div>



      <div class="mt-6 p-2 sm:w-1/4 w-1/2">
      <a href='https://www.m.areawize.com/product-category/property-buy-and-sell/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">

          <img
      className="h-18 max-w-full rounded-lg
      object-cover object-center " src="./assets/catlog/c3.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[10px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>    Buy & Sell Property 
          </h6>  
          </Card></a> 
      </div>



      <div class="mt-5 p-2 sm:w-1/3 w-1/2">
      <a href='https://www.m.areawize.com/product-category/used-cars/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">

          <img
     className="h-18 max-w-full rounded-lg
     object-cover object-center " src="./assets/catlog/c4.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>     Used Car 
          </h6>  
          </Card></a> 
      </div>


      <div class="mt-5 p-2 sm:w-1/4 w-1/2">
      <a href='https://www.m.areawize.com/product-category/car-rent/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">

          <img
     className="h-18 max-w-full rounded-lg
     object-cover object-center " src="./assets/catlog/c5.gif
      "
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>    Car On Rent 
          </h6>  
          </Card></a> 
      </div>



      <div class="mt-5 p-2 sm:w-1/4 w-1/2">
      <a href='https://www.m.areawize.com/product-category/bakery-shop/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">

          <img
        className="h-18 max-w-full rounded-lg
        object-cover object-center " src="./assets/catlog/c6.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>  Bakery Shop
          </h6>  
          </Card></a> 
      </div>



    {/*   <div class="mt-5 p-2 sm:w-1/4 w-1/2">
      <a href='https://www.m.areawize.com/more-to-come/' > <Card>
      <div className="flex items-center justify-center max-w-full h-10 mx-auto mb-4 rounded-full bg-indigo-50 ">

          <img
        className="h-18 max-w-full rounded-lg
        object-cover object-center " src="./assets/catlog/cm.webp"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] lg:text-[11px] font-bold leading-5 tracking-wider uppercase">
          <span>   </span>   More To Come 
          </h6>  
          </Card></a> 
      </div> */}

   
      
    </div><br></br>
  </div>
</section>

 
  </>
  )
}
  