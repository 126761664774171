 

import { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */
import React from 'react'
import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
import Iframe from 'react-iframe'

function Agents() {
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   setTimeout(() => {
     setLoading(false);
   }, 3000);
 }, []);

 return (
   <><Navbarsection /> <div className="h-screen">
     {loading ? <Skeletonui /> : <Iframe url="https://www.m.areawize.com/agent-section/"
       width="100%"
       height="460px" 
       display="block"
       position=""
       frameBorder="0"
       scrolling="yes"
       overflow="hidden"
       z-index="999999" />}
   </div> <Footersection />
   
   </>
 );
}

export default Agents;
 
 
