 
import { useState, useEffect } from "react";
 /* eslint-disable no-unused-vars */
import React from 'react'
 import Cardsk from '../Components/Cardsk'
import Skeletonui from '../Components/Skeletonui'
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
import Iframe from 'react-iframe'

function Searchlisting() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5500);
  }, []);

  return (
    <><Navbarsection /> <div className="fh-screen">
      {loading ? <Skeletonui /> : <Iframe className="mt-[1px]" url="https://www.m.areawize.com/search-listing/"
        width="100%"
        height="700px" 
        display="block"
        position="" 
        scrolling="yes"
        frameborder="0" 
        hspace="" 
        vspace="0" 
        overflow="hidden"
        z-index="999999" />}
    </div> <Footersection /> 
    </>
  );
}

export default Searchlisting;
  




