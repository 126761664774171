/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog2() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > Why You Choose Areawize ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
            We Provide best listing to all <br></br>
            <br></br>  <b> 
          Wider Selection: </b>Classified websites often have a wider selection of listings compared to classified apps. This is because they may cater to a broader audience or specialize in a particular category, like cars or real estate.
          <br></br>  <b> 
Search Functionality: </b>Websites tend to have more advanced search functionalities than apps. This allows you to refine your search by more criteria, like price range, condition, or specific features, making it easier to find exactly what you're looking for.
<br></br>    </p>
    </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="./assets/blog/b2.webp" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
