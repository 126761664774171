/* eslint-disable no-undef */
  
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React, { useRef, Fragment } from "react";
import ActionSheet from "actionsheet-react";
import Navbarsection from "../views/Home/Navbarsection" 
import Categoryslides from "../views/Home/Categoryslides"
 import Footersection from "../views/Home/Footersection"
import Offersection from "../views/Home/Offersection"
import { useState, useEffect } from "react";
/* eslint-disable no-unused-vars */ 
import Iframe from 'react-iframe'
import { IconButton } from "@material-tailwind/react";

export default function Offer() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
    const ref = useRef();
    const handleOpen = () => {
      ref.current.open();
    };
    const handleClose = () => {
      ref.current.close();
    };
  return (
<>
 
<Navbarsection />
<Categoryslides /> 
<Fragment>
      <button onClick={handleOpen}>Open</button>
      <button onClick={handleClose}>Close</button>
      <ActionSheet ref={ref}>
        <div>    <Iframe url="./postads.html"
       width="100%"
       height="480px" 
       display="block"
       position=""
       frameBorder="0"
       scrolling="yes"
       overflow="hidden"
       z-index="999999" /> </div>
     <button ripple={true}
  class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-red-500 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block w-full "
  type="button"
  onClick={handleClose} >
   <i className="fas fa-close" /> Close
</button> </ActionSheet>
    </Fragment> 
<Offersection />
<Footersection />
  
</>
  )
}
 