/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
      <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/blog/b1.webp"
            alt=""
          />
        </div>

      </div>

      <div className="ml-6 mt-6 ">
        <h2 className="text-[18px] font-bold sm:text-4xl"> About <a className="text-green-600" > Areawize  </a> </h2>

        <p className="mt-4 text-gray-600">
       Areawize Get Benefits for Everyone:
Classified websites cater to a diverse audience.<br></br> Here’s how they benefit different users:


<br></br><b> Sellers: </b>Reach a wider audience, conveniently list items, and potentially get faster sales.
<br></br>
<b> 
Buyers: </b>Find great deals on used items, discover local services, and connect with sellers directly.
<br></br>
<b>
Local Businesses:  </b>Advertise services and products hyper-locally to their target market.

<br></br>
<b> </b>

The Future of Classifieds:
The future of classified websites is bright. As technology continues to evolve.
 
        </p>
     
<a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="/Contact"
>
  <span class="text-sm font-medium"> 
Contact Us Now 
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="https://res.cloudinary.com/dailyleadmin/image/upload/v1637069073/Logos/MAIN_DAILYLE_xitqhf.png"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>
 
      </div>
    </div> 
  </div>

  <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-10 lg:grid-cols-2">
      <div>
          <img
            className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
            src="./assets/jobs/j.webp"
            alt=""
          />
        </div>
        <div className="lg:pr-10">
          <a
            href="/"
            aria-label="Go Home"
            title="Logo"
            className="inline-block mb-5"
          >
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
            <i style={{fontSize:40}} class="fa-solid fa-bullseye text-green-500"></i>
            </div>
          </a>
          <h5 className="mb-4 text-4xl font-extrabold leading-none">
          Our Mission
 
          </h5>
          <p className="mb-6 text-gray-900">
      <br></br>  <b>  Variety and Specificity: </b>
Unique Finds: Unearth hidden gems you wouldn’t find in big-box stores. Classifieds cater to niche markets, so you can discover one-of-a-kind items or local services.<br></br>
<b> Supporting Local Businesses: </b>Find skilled professionals like plumbers or electricians in your area, often at competitive rates.
24/7 Access: Ditch the newspaper! <br></br>Classified websites are accessible anytime, anywhere. Post an ad in minutes
          </p>
          <hr className="mb-5 border-gray-300" />
          
        </div>
       
      </div>
    </div>

</section>
  </>
  )
}

 