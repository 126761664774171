/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React from "react"
import ReactDom from "react-dom/client" 
 import About from "./Pages/About.jsx"; 
 import Blog from "./Pages/Blog.jsx"; 
 import Location from "./Pages/Location.jsx";
 import Offer from "./Pages/Offer.jsx"; 
 import Search from "./Pages/Search.jsx"; 
 import Contact from "./Pages/Contact.jsx"; 
 import Aboutusection from "./views/About/Aboutusection.jsx"; 
 import Jobs from "./Pages/Jobs.jsx"; 
 import Support from "./Pages/Support.jsx";
 import News from "./Pages/News.jsx";
 import Terms from "./Pages/Terms.jsx";
 import Privacy from "./Pages/Privacy.jsx";
 import Home from "./Pages/Home.jsx"; 
import Agentsections from "./views/Join/Agentsections.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; 
import Ourpricing from "./Pages/Ourpricing.jsx";
import Blog1 from "./views/Blogs/Blog1.jsx";
import Blog2 from "./views/Blogs/Blog2.jsx";
import Blog3 from "./views/Blogs/Blog3.jsx";
import Pricingsection from "./views/Home/Pricingsection.jsx"; 
    import AllPricing from "./Pricing/AllPricing.jsx";
 import AllServices from "./Services/AllServices.jsx";
import Teamsection from "./views/Teams/Teamsection.jsx";
import Faq from "./Pages/Faq.jsx";
import Plans_and_Pricing from "./Pages/Plans_and_Pricing.jsx";
  import Skeletonui from "./Components/Skeletonui.jsx";
import Cardsk from "./Components/Cardsk.jsx";
import Loginaccount from "./Myaccount/Loginaccount.jsx";
import Accounts from "./Myaccount/Accounts.jsx";
import Mydownload from "./Myaccount/Mydownload.jsx";
import Myplanorder from "./Myaccount/Myplanorder.jsx";
import Signupaccount from "./Myaccount/Signupaccount.jsx";
import Profileaccount from "./Myaccount/Profileaccount.jsx"; 
import Postads from "./Postads/Postads.jsx";
import Postadsection from "./Postads/Postadsection.jsx";
import Searchlisting from "./Listing/Searchlisting.jsx";
import Searchsection from "./Listing/Searchsection.jsx";
import Listing from "./Pages/Listing.jsx";
import NewListing from "./Listing/NewListing.jsx";
import Catlog from "./catlog/Catlog.jsx";
import Our_teams from "./views/Teams/Teamsection.jsx";
import Thanks from "./Thanks.jsx";
import Agents from "./Pages/Agents.jsx";
import Suggestion from "./Pages/Suggestion.jsx";
  
const router = createBrowserRouter([ 
  {
    path: '/',
    element: <Home /> 
  }, 
  { 
    path: '/About',
    element: <About /> 
  }, 
  { 
    path: '/Home',
    element: <Home /> 
  }, 
  
  { 
    path: '/Faq',
    element: <Faq /> 
  }, 
  { 
    path: '/Blog',
    element: <Blog /> 
  }, 
  { 
    path: '/Location',
    element: <Location /> 
  }, 
  { 
    path: '/Offer',
    element: <Offer /> 
  }, 
  { 
    path: '/Search',
    element: <Search /> 
  }, 
  { 
    path: '/Aboutusection',
    element: <Aboutusection /> 
  }, 
  { 
    path: '/Contact',
    element: <Contact /> 
  }, 
  
 
  { 
    path: '/Jobs',
    element: <Jobs /> 
  } ,
  { 
    path: '/Support',
    element: <Support /> 
  } ,
  { 
    path: '/News',
    element: <News /> 
  } ,
  { 
    path: '/Terms',
    element: <Terms /> 
  } ,
  { 
    path: '/Privacy',
    element: <Privacy /> 
  } ,
  { 
    path: '/Agentsections',
    element: <Agentsections /> 
  } ,
  
  { 
    path: '/Blog1',
    element: <Blog1 /> 
  } ,
  { 
    path: '/Blog2',
    element: <Blog2 /> 
  } ,
  { 
    path: '/Ourpricing',
    element: <Ourpricing /> 
  } ,
  { 
    path: '/Pricingsection',
    element: <Pricingsection /> 
  } ,
  { 
    path: '/Blog3',
    element: <Blog3 /> 
  } ,
  
   
  { 
    path: '/Teamsection',
    element: <Teamsection/> 
  } ,
  { 
    path: '/AllServices',
    element: <AllServices/> 
  } ,
  { 
    path: '/AllPricing',
    element: <AllPricing/> 
  } ,
  { 
    path: '/Thanks',
    element: <Thanks/> 
  } ,
  
  { 
    path: '/Suggestion',
    element: <Suggestion/> 
  } ,
  { 
    path: '/Agents',
    element: <Agents/> 
  } ,
  { 
    path: '/Our_teams',
    element: <Our_teams/> 
  } ,
  { 
    path: '/Cardsk',
    element: <Cardsk/> 
  } ,
  { 
    path: '/Loginaccount',
    element: <Loginaccount/> 
  } ,
  { 
    path: '/Accounts',
    element: <Accounts/> 
  } ,
  { 
    path: '/Listing',
    element: <Listing/> 
  } ,
  { 
    path: '/NewListing',
    element: <NewListing/> 
  } ,
  { 
    path: '/Catlog',
    element: <Catlog/> 
  } ,
  { 
    path: '/Postads',
    element: <Postads/> 
  } ,
  { 
    path: '/Searchsection',
    element: <Searchsection/> 
  } ,
  { 
    path: '/Searchlisting',
    element: <Searchlisting/> 
  } ,
  { 
    path: '/Postadsection',
    element: <Postadsection/> 
  } ,
  
  { 
    path: '/Mydownload',
    element: <Mydownload/> 
  } ,
  { 
    path: '/Myplanorder',
    element: <Myplanorder/> 
  } ,
  { 
    path: '/Signupaccount',
    element: <Signupaccount/> 
  } ,
  { 
    path: '/Profileaccount',
    element: <Profileaccount/> 
  } , 
  { 
    path: '/Skeletonui',
    element: <Skeletonui/> 
  }  
]);
  
ReactDom.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
)

export default router;