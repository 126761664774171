/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mt-[6px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-3xl"> FAQ     </h3>
        </div>
      </div>

  <Accordion className="p-4" >
      <Accordion.Panel >
        <Accordion.Title className=" text-blue-900 font-bold" > What is AreaWize ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          AreaWize - We Provide Listing For all User by loacation and get Benifits .

    <br></br>
    offer several advantages for both buyers and sellers looking to advertise. <br></br>

<b> Increased Visibility and Reach:</b>  Compared to traditional classifieds in newspapers, classified apps reach a much wider audience. With a few taps, your ad can be seen by thousands of potential customers in your local area or even nationwide, depending on the app.

<br></br><b> 
Convenience and Speed: </b> Posting an ad on a classified app is quick and easy. You can do it from your phone or computer in just a few minutes. No need to deal with deadlines or printing costs associated with traditional methods.
<br></br><b>  
Targeted Advertising:</b> Many classified apps allow you to target your ads to a specific audience based on location, demographics, and interests. This helps ensure that your ad reaches the people most likely to be interested in what you're offering.
<br></br><b> 
Cost-Effective Marketing: </b>  Compared to other advertising options, classified apps are a very cost-effective way to reach potential customers. Many apps offer free ad listings, while others have paid options that can provide even more exposure.
<br></br><b> 
Faster Transactions:</b>  Classified apps allow for direct communication between buyers and sellers. This can lead to faster transactions and less hassle for everyone involved. 
          </p>
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-blue-900 font-bold" > Why You Choose Areawize ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            We Provide best listing to all <br></br>
            <br></br>  <b> 
          Wider Selection: </b>Classified websites often have a wider selection of listings compared to classified apps. This is because they may cater to a broader audience or specialize in a particular category, like cars or real estate.
          <br></br>  <b> 
Search Functionality: </b>Websites tend to have more advanced search functionalities than apps. This allows you to refine your search by more criteria, like price range, condition, or specific features, making it easier to find exactly what you're looking for.
<br></br>  <b>
Accessibility:  </b>You can access classified websites from any device with a web browser, whereas classified apps require a smartphone or tablet.
<br></br>  <b> 
Comparison Shopping:</b> Websites allow for easier comparison shopping  by having multiple listings side-by-side.  This can be helpful for getting a quick sense of prices and features before contacting sellers.
<br></br>  <b> 
Less Clutter:  </b>Some people find classified apps cluttered or overwhelming due to the visual design and focus on images. Websites can offer a more organized layout with text-based listings.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-blue-900 font-bold" >  How to Post ads on Areawize ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just Visit <a className='font-bold text-[16px] text-green-700' href='/Postads'>
    Apply | On Areawize  </a>Fill The Form & Get Update Soon For Process. 
         
          </p>
             
        </Accordion.Content>
      </Accordion.Panel>

      
    </Accordion>
   </>
  )
}
 