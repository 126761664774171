/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
import { useEffect } from "react";
import Navbarsection from "../views/Home/Navbarsection"
import Bannerslides from "../views/Home/Bannerslides"  
import Footersection from "../views/Home/Footersection"/* 
import Blogsection from "../views/Home/Blogsection"
import Faqsection from "../views/Home/Faqsection"  */
import Feature from "../views/Home/Feature"  
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import Plansdetails from "../views/Home/Plansdetails";

export default function Home() {
 
 /*  Google analytics */  
 
 const location = useLocation();
 useEffect(() => {
   ReactGA.pageview(location.pathname + location.search);
 }, [location]);
 
 
 return (
<> 
<Navbarsection />
<Bannerslides />
<Feature/> 
 {/* 
 <Faqsection /> 
<Blogsection />
 */}
<Footersection /> 
</>
  )
}
 