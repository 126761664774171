/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
import Footersection from '../views/Home/Footersection'
import Feature from '../views/Home/Feature'

export default function Catlog() {
  return (
   <>
   <Navbarsection/>
 <Feature/>
 <Footersection/>
   </>
  )
}
